import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/person/student',
    name: 'student',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-student/Student.vue')
  },
  {
    path: '/:id/:idPro/payments',
    name: 'payments',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-payments/Payments.vue'),
    props: true
  },
  {
    path: '/configuration/academic-record/qualification-criteria',
    name: 'qualificationCriteria',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-ratingCriteria/RatingCriteria.vue'),
  },
  {
    path: '/listAssists/:idCourse',
    name: 'listAssists',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Detail/Course-ListAssists.vue'),
    props: true
  },
  {
    path: '/prueba',
    name: 'prueba',
    component: () => import('../views/prueba.vue')
  },
  {
    path: '/dutic/:id',
    name: 'dutic',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/dutic.vue'),
    props: true
  },
  {
    path: '/course/:id/list-grades',
    name: 'course',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Detail/Course-ListGrades.vue'),
    props: true
  },
  {
    path: '/course/:id/criteria',
    name: 'criteria',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Courses-criteria.vue'),
    props: true
  },
  {
    path: '/course/:id/enrolled',
    name: 'enrolled',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Courses-enrolled.vue'),
    props: true
  },
  {
    path: '/course/:id/horary',
    name: 'horary',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Courses-horary.vue'),
    props: true
  },
  {
    path: '/course/:id',
    name: 'course',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Detail/Course.vue'),
    props: true
  },
  {
    path: '/documentary',
    name: 'statistics',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-statistics/Statistics.vue')
  },
  {
    path: '/procedure',
    name: 'procedure',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-procedure/Procedure.vue')
  },
  {
    path: '/academic/:id/:idProgram/enrolleds',
    name: 'peopleProgram',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-people/program-period/People.vue'),
    props: true
  },
  {
    path: '/program/:id/group',
    name: 'grupo',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-turn/Turns.vue'),
    props: true
  },
  {
    path: '/modules/:id/courses',
    name: 'coursesModule',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Configure/Courses.vue'),
    props: true
  },
  {
    path: '/program/:id/modules',
    name: 'modules',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-module/Modules.vue'),
    props: true
  },
  {
    path: '/requisition/:idProgram/:idReference',
    name: 'requisition',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-requisition/Requisition.vue'),
    props: true
  },
  {
    path: '/permissions',
    name: 'Permissions',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Permissions.vue')
  },
  {
    path: '/security/role',
    name: 'Roles',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-role/Roles.vue')
  },
  {
    path: '/alert',
    name: 'Alerts',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-alert/Alerts.vue')
  },
  {
    path: '/alert2',
    name: 'Alert',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Alert.vue')
  },
  {
    path: '/people/:id',
    name: 'People',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/People.vue'),
    props: true
  },
  {
    path: '/:id/:idPro/courses',
    name: 'Courses',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-course/Courses.vue'),
    props: true
  },
  {
    path: '/modules/:id',
    name: 'Modules',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Modules.vue'),
    props: true
  },
  {
    path: '/cycles/:id',
    name: 'Cycles',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Cycles.vue'),
    props: true
  },
  {
    path: '/academic',
    name: 'Academic',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-period/Periods.vue'),
  },
  {
    path: '/academic/:id/programs',
    name: 'Programs',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-program/Programs.vue'),
    props: true
  },
  {
    path: '/program/data/:id',
    name: 'Program-data',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Program-data.vue'),
    props: true
  },
  {
    path: '/program',
    name: 'Program',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-program/Program-list.vue')
  },
  {
    path: '/configuration/program',
    name: 'Program-config',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-program/Program-config.vue')
  },
  {
    path: '/control',
    name: 'Control',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Control.vue')
  },
  {
    path: '/financial-movement',
    name: 'Finance',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Finance.vue')
  },
  {
    path: '/request/:id',
    name: 'RequestId',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Request.vue'),
    props: true
  },
  {
    path: '/request',
    name: 'Request',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/Request.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-dashboard/Dashboard.vue')
  },
  {
    path: '/users/data/:id',
    name: 'Users-data',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-user/Users-rols.vue'),
    props: true
  },
  {
    path: '/person',
    name: 'Users',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-user/Users.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-profile/Profile.vue')
  },
  {
    path: '/profile/edit',
    name: 'ProfileEdit',
    beforeEnter: autorizationGlobal,
    component: () => import('../views/File-profile/Profile-edit.vue')
  },
  {
    path: '/register',
    name: 'Register',
    beforeEnter: autorizationLogin,
    component: () => import('../views/Register.vue')
  },
  {
    path: '/',
    name: 'Login',
    beforeEnter: autorizationLogin,
    component: () => import('../views/Login.vue')
  },
  /*{
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },*/
  {
    path: "*",
    redirect: "/"
  },
  {
    path: '/:catchAll(.*)',
    redirect: "/"
  }
]

function autorizationGlobal(to, from, next) {
  if (`${localStorage.authenticated}` === 'true') next()
  else next({ name: 'Login' })
}
function autorizationAdmin(to, from, next) {
  if (`${localStorage.user}` === 'Administrador') next()
  else next({ name: 'Dashboard' })
}
function autorizationLogin(to, from, next) {
  if (`${localStorage.authenticated}` === 'true') next({ name: 'Dashboard' })
  else next()
}

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
