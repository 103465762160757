import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "axios";
import firebase from "firebase/compat/app";
import 'firebase/compat/storage'
import UUID from "vue-uuid";
import VueGlobalMessage from 'vue-global-message';
import loader from "vue-ui-preloader";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import VCalendar from 'v-calendar';

import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);
Vue.use(VCalendar)
Vue.use(VueGlide)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false
Vue.prototype.$axios = axios.create({
  //baseURL:  'https://generic-spring.herokuapp.com/',
  //baseURL: 'https://ucps-back.herokuapp.com/',
  //baseURL: 'http://unsa-ucps.com:8080/',
  //baseURL: 'https://backend.ucps.edu.pe:8443/controller/',
  baseURL: 'https://fips-ucps.unsa.edu.pe/backend/controller/',
});
Vue.use(UUID);
//https://www.npmjs.com/package/vue-global-message
Vue.use(VueGlobalMessage,
  {
    'zindex': 1000,   // default: 1000
    'duration': 8000 // default: 500ms
  }
)

firebase.initializeApp({
  apiKey: "AIzaSyB452I7hjCq3U9mBum76sQtcYyAvMLXOrA",

  authDomain: "ucps-7d113.firebaseapp.com",

  projectId: "ucps-7d113",

  storageBucket: "ucps-7d113.appspot.com",

  messagingSenderId: "869979173781",

  appId: "1:869979173781:web:0a642ed4a635e3336c7c9c"

});
Vue.use(loader);

//Datos globales

//Enlace imagenes
Vue.prototype.$urlFirebase = 'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/'
//Enlace documentos de notas
Vue.prototype.$urlFirebaseDocument = 'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/EvaluationDocuments%2F'
//Enlace horario imagenes
Vue.prototype.$urlFirebaseSchedule = 'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/ScheduleImages%2F'
//Enlace documentos variados
Vue.prototype.$urlFirebaseAssortedDocuments = 'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/AssortedDocuments%2F'
//Vue.prototype.$prueba = true;
//Funciones globales

//Error en consulta
Vue.prototype.$errorQuery = function (fact) {
  /*console.log(this.$prueba);
  if (this.$prueba) {
    this.$prueba = false;
    console.log(this.$prueba);
    console.log(fact);
    //Token vencido
    this.$store.commit("logout");
    if (fact.response.data.error === "Unauthenticated") {
      alert("Sesión expirada!");
    } else {
      alert("Error de sistema");
    };
    window.location.href = window.location.origin;
  }*/
  console.log(fact);
  this.$store.commit("logout");
  if (fact.response.data.error === "Unauthenticated") {
    localStorage.setItem("queryError", "Unauthenticated");
  } else {
    localStorage.setItem("queryError", "Error");
  };
  window.location.href = window.location.origin;
};

//Verificación rol
Vue.prototype.$checkRole = function (role) {
  let arr = JSON.parse(`${localStorage.authorities}`);
  for (let i in arr) {
    if (arr[i].authority == role) {
      return true;
    }
  }
  return false;
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
