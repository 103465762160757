import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('authenticated');
      localStorage.removeItem('username');
      localStorage.removeItem('authorities');
      localStorage.removeItem('imageUrl');
    }
  },
  actions: {
  },
  modules: {
  }
})
