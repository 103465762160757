<template>
  <div id="NavSidebar">
    <b-navbar :fixed="fixed" toggleable="md" style="background-color: #1d425e; z-index: 1000">
      <b-navbar-brand style="margin-left:-10px" v-show="verificado() === true"
        ><b-button variant="transparent" @click="$parent.collapsedOn()"
          ><b-icon icon="list" variant="light" /></b-button
      ></b-navbar-brand>
      <b-navbar-brand
        style="
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          font-size: xx-large;
          color: rgb(243, 243, 243);
        "
        to="/dashboard"
        >UCPS</b-navbar-brand
      >

      <b-navbar-toggle v-show="verificado() == true" target="nav-collapse"
        ><b-icon icon="arrow-bar-down"
      /></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--<b-nav-item-dropdown
            v-show="verificado() === true"
            style="margin-top: 6px"
            variant="link"
            right
          >
            <template #button-content>
              <b-icon-bell />
            </template>
            <div class="container">
              <b-dropdown-item href="#">Notificación01</b-dropdown-item>
              <b-dropdown-item href="#">Notificación02</b-dropdown-item>
              <b-dropdown-item href="#">Notificación03</b-dropdown-item>
            </div>
          </b-nav-item-dropdown>-->

          <!--pruebaDiseñoAlerta-->
          <!--
          <b-nav-item-dropdown
            toggle-class="text-light"
            v-show="verificado() === true"
            right
          >
            <template #button-content>
              <b-avatar
                badge
                badge-left
                badge-top
                icon="bell"
                variant="light"
              ></b-avatar>
            </template>
            <div class="container drop px-2">
              <mdb-card class="example-card w-100 scrollbar-ripe-malinka">
                <mdb-card-body>
                  <h4>Notificaciones</h4>
                  <b-list-group flush>
                    <div v-for="index in 5" :key="index">
                      <b-list-group-item class="pb-1" href="/alertas">
                        <div class="d-flex w-100 justify-content-between">
                          <h5 class="mb-1">Pago de matrícula</h5>
                          <small> 2022-03-23 (15:08)</small>
                        </div>

                        <p class="mb-1">CYNTHYA JIMENA - MAMANII</p>
                      </b-list-group-item>
                      <div class="container row pb-2">
                        <div class="col-10">
                          <small class="pl-1">cynthyajmp@gmail.com</small>
                        </div>
                        <div class="col-2">
                          <li class="dropdown-submenu">
                            <a href="#"><b-icon-three-dots /></a>
                            <ul class="dropdown-menu p-0">
                              <li><a class="dropdown-item" href="#">Seleccionar</a></li>
                              <li><a class="dropdown-item" href="#">Observar</a></li>
                              <li><a class="dropdown-item" href="#">Eliminar</a></li>
                            </ul>
                          </li>
                        </div>
                      </div>
                    </div>
                  </b-list-group>
                </mdb-card-body>
              </mdb-card>
            </div>
          </b-nav-item-dropdown>
          -->
          <!---->

          <!--Alarma-->
          <div v-if="desserts.length != 0">
            <b-nav-item :to="desserts[0].href" right>
              <b-avatar
                badge
                badge-left
                badge-top
                :icon="desserts[0].icon"
                variant="light"
              ></b-avatar>
            </b-nav-item>
          </div>
          <!---->

          <b-nav-item-dropdown
            toggle-class="text-light"
            v-show="verificado() === true"
            right
          >
            <template #button-content>
              <b-img
                v-if="imageUrl != null"
                v-bind="mainProps"
                type="button"
                rounded="circle"
                alt="Circle image"
                :src="
                  'https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/' +
                  imageUrl
                "
              />
              <b-img
                v-else
                v-bind="mainProps"
                type="button"
                rounded="circle"
                alt="Circle image"
                src="https://picsum.photos/250/250/?image=54"
              />
            </template>
            <div class="container">
              <b-dropdown-item disabled>
                <h4>{{ nameAdmin() }}</h4></b-dropdown-item
              >
              <b-dropdown-item class="w-100" to="/profile">
                <b-icon-person /> Mi Perfil</b-dropdown-item
              >
              <!--<b-dropdown-item href="#"><b-icon-gear/> Configuración</b-dropdown-item>-->

              <b-dropdown-item
                class="w-100"
                href="/"
                @click="$store.commit('logout')"
                ><b-icon-door-open /> Cerrar Sesión</b-dropdown-item
              >
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: "NavSidebar",
  props: {
    fixed: {
      type: String,
      default: "",
    },
  },
  created() {
    this.initialize();
    if (`${localStorage.imageUrl}` != "null")
      this.imageUrl = `${localStorage.imageUrl}` + "?alt=media";
  },
  data() {
    return {
      desserts: [],
      imageUrl: null,
      mainProps: {
        blankColor: "#777",
        width: 40,
        height: 40,
      },
    };
  },
  methods: {
    async initialize() {
      await this.$axios
        .get("/interface/top", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    nameAdmin() {
      return `${localStorage.username}`;
    },
    verificado() {
      if (`${localStorage.authenticated}` === "undefined") return false;
      else return true;
    },
    verificadoRol(rol) {
      if (`${localStorage.authorities}` != "undefined") {
        var roles = JSON.parse(`${localStorage.authorities}`);
        for (var i = 0; i < roles.length; i++) {
          if (roles[i].authority === rol) {
            return true;
          }
          if (roles[i].authority === "Administrador") {
            return true;
          }
        }
        return false;
      }
    },
  },
};
</script>
<style scoped>
.navbar-light .navbar-toggler {
  color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
}
.drop {
  width: 380px;
}
</style>
<style scoped>
.scrollbar-ripe-malinka::-webkit-scrollbar-track {
  background-color: #e9e7e7;
  border-radius: 10px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar {
  width: 12px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: linear-gradient(120deg, #2679b8 0%, #1d425e 100%);
}

.example-card {
  position: relative;
  overflow-y: scroll;
  height: 380px;
}
.down:hover {
  display: block;
}
</style>
<style scoped>
.list-group-item {
  border: none !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
</style>

<style scoped>
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  display: none;
  position: absolute;
  top: -71px;
  right: 100%;
  left: auto;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
</style>

