<template>
  <div v-if="componentCreated()">
    <div v-if="isOnMobile">
      <div
        id="demo"
        :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]"
      >
        <div>
          <nav-sidebar ref="sidebar" />
          <b-container style="margin-top: 2%">
            <router-view />
          </b-container>
        </div>
        <sidebar-menu
          :menu="desserts"
          :collapsed="collapsed"
          :show-one-child="true"
          width="300px"
          @toggle-collapse="onToggleCollapse"
          @item-click="onItemClick"
          style="z-index: 1001"
        />
        <div
          v-if="isOnMobile && !collapsed"
          class="sidebar-overlay"
          @click="collapsed = true"
        />
      </div>
    </div>
    <div v-else>
      <nav-sidebar fixed="top" ref="sidebar" />
      <div
        id="demo"
        :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]"
      >
        <div>
          <b-container style="margin-top: 90px">
            <router-view />
          </b-container>
        </div>
        <sidebar-menu
          :menu="desserts"
          :collapsed="collapsed"
          :show-one-child="true"
          width="300px"
          @toggle-collapse="onToggleCollapse"
          @item-click="onItemClick"
          style="padding-top: 74px; z-index: 900"
        />
        <div
          v-if="isOnMobile && !collapsed"
          class="sidebar-overlay"
          @click="collapsed = true"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <b-container style="margin-top: 20px">
      <router-view />
    </b-container>
  </div>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import NavSidebar from "./components/NavSidebar.vue";
export default {
  components: {
    SidebarMenu,
    NavSidebar,
  },
  name: "App",
  data() {
    return {
      desserts: [],
      collapsed: false,
      themes: [
        {
          name: "Default theme",
          input: "",
        },
        {
          name: "White theme",
          input: "white-theme",
        },
      ],
      selectedTheme: "white-theme",
      isOnMobile: false,
    };
  },
  created() {
    //Expiración token o error de sistema
    if (`${localStorage.queryError}` === "Unauthenticated") {
      alert("Sesión expirada!");
    } else if (`${localStorage.queryError}` === "Error") {
      alert("Error de sistema");
    }
    localStorage.setItem("queryError", null);
    //
    if (`${localStorage.authenticated}` === "true") {
      this.sidebarData();
    }
    //inicialización imagenUrl
    localStorage.setItem("imageUrl", null);
    //tiempo carga inicial
    setTimeout(() => {
      this.selected = "";
    }, 500);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  methods: {
    collapsedOn() {
      this.collapsed = !this.collapsed;
    },
    async sidebarData() {
      await this.$axios
        .get("/interface/side", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.arrayData(res.data);
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    arrayData(array) {
      this.desserts = [
        {
          href: "/dashboard",
          title: "Pagina principal",
          icon: "fas fa-home",
        },
        {
          title: "Trámites",
          icon: "fa fa-envelope",
          child: [
            {
              href: "/program",
              title: "Matrícula",
              icon: "far fa-bookmark",
            },
            /*{
              href: "/requisition/100010003/ ",
              title: "Trámite Constancia Estudios",
              icon: "fa fa-user-tie",
            },*/
          ],
        },
        {
          href: "/procedure",
          title: "Mis trámites",
          icon: "fa fa-arrow-alt-circle-up",
        },
        /*{
          href: "/dutic/dutic",
          title: "Aula Virtual",
          icon: "fa fa-window-restore",
        },*/
        {
          href: "/dutic/soporte",
          title: "Soporte estudiantil",
          icon: "fas fa-headset",
        },
      ];
      if (array.allAcademicElement.length != 0) {
        this.desserts.push({
          header: true,
          title: "Área Académica",
          hiddenOnCollapse: true,
        });
        this.pushArray(array.allAcademicElement, this.desserts);
      }
      if (array.allAdministrativeElement.length != 0) {
        this.desserts.push({
          header: true,
          title: "Área Administrativa",
          hiddenOnCollapse: true,
        });
        this.pushArray(array.allAdministrativeElement, this.desserts);
      }
    },
    pushArray(array, temp) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].allNode.length == 0) {
          temp.push({
            href: array[i].href,
            title: array[i].text,
            icon: array[i].icon,
          });
        } else {
          temp.push({
            title: array[i].text,
            icon: array[i].icon,
            child: [],
          });
          this.pushArray(array[i].allNode, temp[temp.length - 1].child);
        }
      }
    },
    componentCreated() {
      if (`${localStorage.authenticated}` === "true") return true;
      else return false;
    },
    onToggleCollapse(collapsed) {
      console.log(collapsed);
      this.collapsed = collapsed;
    },
    onItemClick(event, item, node) {
      console.log("onItemClick");
      // console.log(event)
      // console.log(item)
      // console.log(node)
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
};
</script>

<style lang="scss">
#demo {
  padding-left: 300px;
  transition: 0.3s ease;
}
#demo.collapsed {
  padding-left: 50px;
}
#demo.onmobile {
  padding-left: 50px;
}
.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}
pre {
  font-family: Consolas, monospace;
  color: #000;
  background: #fff;
  border-radius: 2px;
  padding: 15px;
  line-height: 1.5;
  overflow: auto;
}
.v-sidebar-menu {
  background-color: #1d425e !important;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: transparent !important;
  //border: #fff solid 1px;
}
.v-sidebar-menu .vsm--toggle-btn {
  display: none !important;
}
/*.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  background-color: rgba(30,30,33,0.5) !important;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: rgba(30,30,33,0.5) !important;
}*/
</style>
<style>
.container {
  padding-right: 1% !important;
  padding-left: 1% !important;
}
.card-body {
  background-color: rgb(243, 243, 243);
  padding: 2% 2% !important;
}
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.modal-dialog {
  max-width: 800px !important;
}
</style>